<template>
   <div class="reportFormsBox">

    <div class="reportList">
           <h2>包裝追蹤</h2>
           <div class="flexBox">
                   <div class="itemBox">
                         <div class="left">
                              <span>還要包裝</span>
                               <h2>{{foodOverviewData.needPackage?foodOverviewData.needPackage:0}}</h2>
                         </div>
                         <img src="../../../assets/img/ico_a.jpg" alt="">

                   </div>
                   <div class="itemBox">
                         <div class="left">
                              <span>正在包裝</span>
                               <h2>{{foodOverviewData.packaging?foodOverviewData.packaging:0}}</h2>
                         </div>
                         <img src="../../../assets/img/ico_b.jpg" alt="">

                   </div>
                   <div class="itemBox">
                         <div class="left">
                              <span>已經完成</span>
                               <h2>{{foodOverviewData.completed?foodOverviewData.completed:0}}</h2>
                         </div>
                         <img src="../../../assets/img/ico_c.jpg" alt="">

                   </div>
                   <div class="itemBox">
                         <div class="left">
                              <span>已經車走</span>
                               <h2>{{foodOverviewData.beenSentOut?foodOverviewData.beenSentOut:0}}</h2>
                         </div>
                         <img src="../../../assets/img/ico_d.jpg" alt="">
                   </div>
           </div>
    </div>

     <div class="tableBox">
          <h2>
            Task tracking
          </h2>

          <div class="tableList">
            <div class="leftTitle">
                <span :class="activeIndex===item.id?'active':''" v-for="item in tabTitle" :key="item.id" @click="activeBtn(item)">
                      {{item.name}}
                 </span>
            </div>
             <div class="rightInput">
                <el-date-picker
                style="margin-right: 10px;width: 513px;"
                v-model="dateTime"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                @change="timeBtn"
                clearable
                @clear="clearBtn"
                start-placeholder="開始日期"
                end-placeholder="結束日期">
              </el-date-picker>

               <el-input placeholder="請輸入執貨單號碼" v-model="bookingNo" class="input-with-select" clearable @clear="handleClear">
                <el-button slot="append" icon="el-icon-search" @click.native="searchBtn"></el-button>
              </el-input>

             </div>

           </div>

           <tableBase
                 size='mini'
                  :isSelection='false'
                  :isIndex='false'
                  :isPagination='true'
                  :isHandle='true'
                  :tableData='todayData.tableData'
                  :tableCols='todayData.tableCols'
                  :pagination='todayData.pagination'
                  @handleCurrentChange='handleCurrentChange'
                 >
          </tableBase>
          <stateProp ref="stateDrom" :foodDetailId="foodDetailId"></stateProp>
          <processProp ref="processDrom" :foodDetailId="foodDetailId"></processProp>
     </div>

     <el-dialog
      title="已完成"
      :visible.sync="completedVisible"
      width="500px"
      style="text-align:left;"
      >
      <div class="remarkBxo">
          <span>
            備註:
          </span>
          <el-input
          type="textarea"
          :rows="3"
          placeholder="請輸入內容"
          v-model="textareaRemark">
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" style="text-decoration: underline" @click="backToPackaging">變更為正在包裝</el-button>
        <el-button type="primary" @click="completedVisibleBtn">確 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="查看"
        style="text-align:left !important;"
        :visible.sync="pdfVisible"
        width="60%"

        center>
      <div style="height:600px">
        <iframe
            :src="baseUrl"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
        ></iframe>
      </div>

    </el-dialog>
     <!--  修改訂單信息-->
     <el-dialog class="updateItemDialog"
                title="修改"
                style="text-align:left !important;"
                :visible.sync="updateItemVisible"
                width="75%"
                center>
       <div>
         <el-form :model="updateItemParam">
           <el-form-item label-width="120px">
             <template v-slot:label>
               <span class="required-label">訂單號：</span>
             </template>
             <el-input disabled style="width: 75%;" v-model="updateItemParam.bookingNo"/>
           </el-form-item>
           <el-form-item label-width="120px">
             <template v-slot:label>
               <span class="required-label">送貨日期：</span>
             </template>
             <el-date-picker
                 v-model="updateItemParam.deliveryTime"
                 style="width: 75%;"
                 value-format="dd/MM/yyyy HH:mm:ss"
                 format="dd/MM/yyyy HH:mm:ss"
                 type="datetime"
                 placeholder="請選擇過期時間"
             >
             </el-date-picker>
           </el-form-item>
         </el-form>
         <div slot="footer" class="dialog-footer-update-item">
           <el-button @click="handleUpdateItem('cancel')">取 消</el-button>
           <el-button type="primary" @click="handleUpdateItem('confirm')">確 定</el-button>
         </div>
       </div>
     </el-dialog>


   </div>
</template>

<script>
import {Message} from "element-ui";
import {handle, trackList, trackOverview, updatePackageTrack} from '../../../request/requrst'
import tableBase from '../../../components/tbale.vue'
import stateProp from './child/state.vue'
import processProp from './child/stateTwo.vue'

export default {
  components: {
    tableBase,
    stateProp,
    processProp,
  },
  data() {
    return {
      completedVisible: false,
      textareaRemark: '',
      activeIndex: 1,
      packageId: '',
      foodDetailId: '',
      bookingNo: '',
      status: '',
      dateTime: '',
      foodOverviewData: [],
      pdfVisible: false,
      updateItemVisible: false,
      updateItemParam: {
        packageId: '',
        bookingNo: '',
        deliveryTime: ''
      },

      pdfUrl: '',
      tabTitle: [
        {
          id: 1,
          name: '今日還要包裝',
          status: ''
        },
        {
          id: 2,
          name: '正在包裝',
          status: 1
        },
        {
          id: 3,
          name: '已經完成',
          status: 2
        },
        {
          id: 4,
          name: '已經發貨',
          status: 3
        },
        {
          id: 5,
          name: '未匹配',
          status: 4
        }
      ],
      todayData: {
        tableData: [],
        tableCols: [
          {
            label: '訂單產生日期',
            prop: 'createTime'

          },
          {
            label: '送貨日期',
            prop: 'deliveryTime',
            align: 'center'
          },
          {
            label: '訂單號',
            prop: 'bookingNo',
            align: 'center'
          },
          {
            label: '執貨單號碼',
            prop: 'packageId',
            align: 'center'
          },

          {
            label: 'DELIVERED TO',
            prop: 'deliveredTo',
            align: 'center'
          },
          {
            label: '來源',
            prop: 'packageType',
            align: 'center',
            type: 'Button',
            render: (h, params) => {
              let texts = ''
              switch (params.row.packageType) {
                case 0:
                  texts = 'klook'
                  break;
                case 1:
                  texts = 'opencart'
                  break;
                default:
              }
              return (
                  h("el-button", {
                    props: {type: 'text'},
                    style: {color: params.row.packageType !== 0 ? '#e6a23c' : '#409eff'}
                  }, texts)

              )
            },
          },
          {
            label: '訂單PDF',
            prop: 'filePath',
            type: 'Button',
            align: 'center',
            render: (h, params) => {

              let typeChile = ''
              let texts = ''
              switch (params.row.packageType) {

                case 0:
                  typeChile = 'primary'
                  texts = '查看'
                  break;
                case 1:
                  typeChile = 'info'
                  texts = '--'
                  break;
                default:
              }
                        return (
                        h('el-button',{
                          props: {
                            type: typeChile,
                            type: 'text'
                          },
                          // style: {display: texts ? 'inline-block' : 'none'},
                          on:{
                            click: () => {
                                this.viewItem(params.row)
                              }
                          }
                      },texts)
                      )

                    }
                },
                {
                    label:'狀態',
                    prop:'packageStatus',
                    type:'Button',
                    align:'center',
                    render: (h, params) => {
                           let texts = ''
                           let typeChile = ''
                           switch(params.row.packageStatus)
                          {
                              case 0:
                              typeChile='primary'
                              texts = '今日還要包裝'
                                  break;
                              case 1:
                              typeChile=''
                              texts = '正在包裝'
                                  break;
                              case 2:
                              typeChile='success'
                              texts = '包裝完成'
                                  break;
                              case 3:
                              typeChile='warning'
                              texts = '已經發貨'
                                  break;
                               case 4:
                              typeChile='info'
                              texts = '未匹配'
                                  break;

                              case 5:
                              typeChile='danger'
                              texts = '取消'
                                  break;
                              default:

                          }
                      return (
                          h('el-button', {
                            domProps: {
                              innerText: texts,
                              size: 'mini'
                            },
                            props: {
                              type: typeChile,
                              size: 'small'
                            },
                            on: {
                              click: () => {
                                this.editItem(params.row)
                              }
                            }
                          }, texts)
                      )
                    }
                },
          {
            label: '操作',
            type: 'Button',
            align: 'center',
            render: (h, params) => {
              let texts = '修改'
              return (
                  h('el-button', {
                    domProps: {
                      innerText: texts,
                      size: 'mini'
                    },
                    on: {
                      click: () => {
                        this.updateItem(params.row)
                      }
                    }
                  }, texts)
              )
            }
          }
        ],
        pagination: {
          page: 1,//分頁數
          limit: 10,//每頁條數
          total: 0//總條數
        }
      }

        }
    },
    watch:{
      completedVisible(val, value){
            if(val===false){
                this.textareaRemark=''
            }
        }
    },
    computed:{
        baseUrl(){
            return process.env.VUE_APP_BASE_Dis+this.pdfUrl
        }
    },
    methods: {handleClear(){
        this.getList()
      },
      timeBtn(){
        this.getList()
      },
      clearBtn(){
        this.dateTime =''
        this.getList()
      },
      viewItem(item){
        if(item.packageType!==0){

          return
        }
        console.log(item)
           this.pdfVisible=true
           this.pdfUrl=item.filePath
      },
      backToPackaging(){
        let param ={}
        param.packageId = this.foodDetailId
        param.handleType=1
        param.remark=this.textareaRemark
        handle('api/package/track/handle', 'PUT', param).then((res) => {
          this.completedVisible=false
          Message({
            type: "success",
            message: res.msg
          });
          this.getList()
          this.foodOverviewEvent()
        }).catch((err) => {
          console.log(err.message)
        })
      },
      completedVisibleBtn() {
        let param = {}
        param.packageId = this.foodDetailId
        param.handleType = 3
        param.remark = this.textareaRemark
        handle('api/package/track/handle', 'PUT', param).then((res) => {
          this.completedVisible = false
          Message({
            type: "success",
            message: res.msg
          });
          this.getList()
          this.foodOverviewEvent()
        }).catch((err) => {
          console.log(err.message)
        })
      },
      editItem(row) {
        if (row.packageStatus == 4) {
          this.$refs.stateDrom.stateDialogVisible = true
          this.foodDetailId = row.packageId
          setTimeout(() => {
            this.$refs.stateDrom.foodDetailsData()
          }, 500);
        }
        if (row.packageStatus == 1) {
          this.$refs.processDrom.processDialogVisible = true
          this.foodDetailId = row.packageId
          setTimeout(() => {
            this.$refs.processDrom.foodDetailsData()
          }, 500)
        }
        if (row.packageStatus == 2) {
          this.completedVisible = true
          this.foodDetailId = row.packageId
        }
      },
      updateItem(row) {
        this.updateItemVisible = true
        this.updateItemParam.bookingNo = row.bookingNo
        this.updateItemParam.deliveryTime = row.deliveryTime
        this.updateItemParam.packageId = row.packageId
      },
      handleUpdateItem(val) {
        if (val === 'cancel') {
          this.updateItemVisible = false
          this.updateItemParam = {}
          return
        }
        if (val === 'confirm') {
          const params = this.updateItemParam
          updatePackageTrack('api/package/track', 'put', params).then((res) => {
            const code = res.code
            if (code === 500) {
              Message({
                type: "error",
                message: res.msg
              });
            } else {
              this.updateItemVisible = false
              this.getList()
              Message({
                type: "success",
                message: res.msg
              });
            }
          }).catch((err) => {
            console.log(err.message)
          })
        }
      },
      activeBtn(item) {
        this.activeIndex = item.id
        this.status = item.status
        this.todayData.pagination.page = 1
        this.todayData.pagination.limit = 10
        this.getList()
      },
      handleCurrentChange(val) {
        this.getList();
      },
      //  搜索
      searchBtn() {
        this.todayData.pagination.page = 1
        this.todayData.pagination.limit = 10
        this.getList()
      },
      getList() {
        let param = {}
        param.status = this.status
        param.bookingNo = this.bookingNo
        param.pageNum = this.todayData.pagination.page
        if (this.dateTime) {
          param.startTime = this.dateTime.length > 0 ? this.dateTime[0] : ''
          param.endTime = this.dateTime.length > 0 ? this.dateTime[1] : ''
        }

        param.pageSize = this.todayData.pagination.limit
        trackList('api/package/track/list', 'get', param).then((res) => {
          this.todayData.tableData = res.data.rows || []

          this.todayData.pagination.total = res.data.total
        }).catch((err) => {
          console.log(err.message)
        })

      },
      foodOverviewEvent(){
        let param ={}
        trackOverview('api/package/track/overview', 'get', param).then((res) => {
            this.foodOverviewData=res.data
           }).catch((err) => {
            console.log(err.message)
         })
      }
    },
    created(){
       this.getList()
       this.foodOverviewEvent()
    }
  }
</script>

<style lang="scss" scoped>
.reportFormsBox{
  .dialog-footer{
    display: flex;
    justify-content: space-between;
  }
  display: flex;
  flex-direction: column;
  .reportList{
    width: 100%;
    flex-direction: column;
    h2{
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
    }
    .flexBox{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemBox{
        width: 24%;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        box-sizing: border-box;
        padding:20px 10px;
        .left{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span{
            display: block;
            margin-bottom: 10px;
            font-size: 22px;
          }
          h2{
            font-size: 40px;
          }
        }
        img{
          width: 75px;
          height: 75px;
        }
      }
      .itemBox:first-child{
        background-color: #F3E1E5;
      }
      .itemBox:nth-child(2){
        background-color: #E5DDF4;
      }
      .itemBox:nth-child(3){
        background-color: #D0E7DA;
      }
      .itemBox:last-child{
        background-color: #DCE4F5;
      }
    }
  }
  .tableBox{
    display: flex;
    flex-direction: column;
    h2{
      width: 100%;
      text-align: left;
      margin: 10px 0;
    }
  }
}
.tableBox{
    display: flex;
    flex-direction: column;
    h2{
      width: 100%;
      text-align: left;
      margin: 10px 0;
    }
    .tableList{
      display: flex;
      justify-content: space-between;
      height: 40px;
      .leftTitle{
        display: flex;
        border-bottom: 1px solid #e4e7ed;
        flex: 1;
        span{
          padding:0 13px;
          height: 40px;
          margin-right: 6px;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          cursor: pointer;
          border: 1px solid #e4e7ed;
          border-bottom: none;
          border-radius: 4px 4px 0 0;
          box-sizing: border-box;
        }

        span.active {
          color: #082844;
          font-weight: bold;
          height: 41px;
          background-color: #fff;
          border-right-color: #dcdfe6;
          border-left-color: #dcdfe6;
          border-bottom-color: #fff;
        }
      }

      .rightInput {
        width: 500px;
        display: flex;
        align-items: center;
      }
    }
}

.remarkBxo {
  display: flex;

  span {
    width: 45px;
  }
}

.updateItemDialog {
  ::v-deep .el-dialog {
    max-width: 600px;
  }
}

.dialog-footer-update-item {
  display: flex;
  justify-content: flex-end;
}

</style>
