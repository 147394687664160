<template>
  <div class="reportFormsBox">


    <div class="tableBox">
         <h2>
          補貨列表
         </h2>

         <!-- <div class="btnBoxtype"> -->
          
          <!-- <el-button @click.native="inventory(0)">盤點</el-button> -->
          <!-- <el-button @click.native="inventory(1)" type="primary" >補貨</el-button> -->
         <!-- </div> -->

         <!-- <div class="tableList">
           <div class="leftTitle">
               <span :class="activeIndex===item.id?'active':''" v-for="item in tabTitle" :key="item.id" @click="activeBtn(item)">
                     {{item.name}}
                </span>
           </div>
            <div class="rightInput">
             <el-input placeholder="请输入执货单号码" v-model="packageId" class="input-with-select">
               <el-button slot="append" icon="el-icon-search" @click.native="searchBtn"></el-button>
             </el-input>
            </div>
             
          </div> -->

          <!-- <tableBase 
                size='mini'
                 :isSelection='false'
                 :isIndex='false'
                 :isPagination='true'
                 :isHandle='true'
                 :tableData='todayData.tableData' 
                 :tableCols='todayData.tableCols' 
                 :pagination='todayData.pagination'
                 @handleCurrentChange='handleCurrentChange'
                >
         </tableBase> -->
          <propsBox ref="propsRef" :importType="importType"  :tableIemData="tableIemData"></propsBox>
    </div>


  </div>
</template>

<script>
import { Message } from "element-ui";
import {trackList } from '../../../request/requrst'
// import tableBase from '../../../components/tbale.vue'
import propsBox from './child/prop.vue'
 export default {
   components:{
     propsBox
   },
   data(){
       return{
         packageId:'',
         status:'',
         importType:1,
         foodOverviewData:[],
         tableIemData:[],
         


       }
   },
   methods: {
   },
   created(){

   }
 }
</script>

<style lang="scss" scoped>
.reportFormsBox{
 display: flex;
 flex-direction: column;
 .reportList{
   width: 100%;
   flex-direction: column;
   h2{
     width: 100%;
     text-align: left;
     margin-bottom: 78px;
   }
   .flexBox{
     width: 100%;
     display: flex;
     justify-content: space-between;
     .itemBox{
       width: 350px;
       display: flex;
       justify-content: space-between;
       border-radius: 10px;
       .left{
         margin-top: 38px;
         margin-left: 28px;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         span{
           display: block;
           margin-bottom: 10px;
         }
       }
       img{
         margin: 32px 33px 84px 0;
       }
     }
     .itemBox:first-child{
       background-color: #F3E1E5;
     }
     .itemBox:nth-child(2){
       background-color: #E5DDF4;
     }
     .itemBox:nth-child(3){
       background-color: #D0E7DA;
     }
     .itemBox:last-child{
       background-color: #DCE4F5;
     }
   }
 }
 .tableBox{
   display: flex;
   flex-direction: column;
   h2{
     width: 100%;
     text-align: left;
     margin: 10px 0;
   }
 }
}
.tableBox{
   display: flex;
   flex-direction: column;
   h2{
     width: 100%;
     text-align: left;
     margin: 10px 0;
   }
   .tableList{
     display: flex;
     justify-content: space-between;
     .leftTitle{
       display: flex;
       span{
         margin: 0 20px;
         height: 40px;
         box-sizing: border-box;
         line-height: 40px;
         display: inline-block;
         list-style: none;
         font-size: 14px;
         font-weight: 500;
         color: #999999;
         cursor: pointer;
       }
       span.active{
         color: #082844;
         font-weight: bold;
         border-bottom: 2px solid #082844;
       }
     }
     .rightInput{
       width: 400px;
       display: flex;
       align-items: center;
     }
   }
 }
 .btnBoxtype{
  width: 100%;
  display: flex;
  margin-bottom: 38px;
 }
</style>