<template>
<!--    <div>
        <el-dialog
            title="未匹配"
            :visible.sync="stateDialogVisible"
            width="600px"
            style="text-align: left;"
            >
            <div class="stateBox">
                &lt;!&ndash; <div class="textareaBox">
                    <span>商户id匹配:</span>
                    <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入商品id用逗号隔开"
                    v-model="textarea">
                   </el-input>
                </div>    &ndash;&gt;
                <div class="listTitle">
                     <h3>食材列表</h3>
                    <div class="itemBOX" v-for="(item,index) in foodsDetails" :key="item.foodId">
                        <div class="leftBox">
                            <span :style="item.foodId?'':'color: red'">{{item.foodId?item.foodId:'未匹配到id'}}</span>
                            <span >{{item.foodName}}</span>
                            <el-button type="primary"   v-if="!item.foodId"  @click.native="modifyBtn(item)" size="small"   style="margin-left:14px;">新增</el-button>
                        </div>
                           &lt;!&ndash; <i class="el-icon-error" v-if="item.status===1"></i> &ndash;&gt;
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click.native="rematchBtn">重新匹配</el-button>
            </span>
            </el-dialog>

            <addProp ref="addDrom" :foodDetailId="foodDetailId"></addProp>
    </div>-->
  <div>
    <el-dialog
        title="未匹配"
        :visible.sync="stateDialogVisible"
        width=45%
        style="text-align: center;top: 5px"
    >
      <h3>食材列表</h3>
      <el-table :data="this.foodsDetails">
        <el-table-column prop="foodId" label="食材ID">
          <template slot-scope="{ row }">
            <span :style="{ color: row.foodId ? '' : 'red' }">{{ row.foodId || '食材ID未匹配' }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="食材名称">
          <template slot-scope="props">
            {{ props.row.foodAlias ? props.row.foodName + '|' + props.row.foodAlias : props.row.foodName }}
            <el-button type="primary" v-if="!props.row.foodId"  @click.native="modifyBtn(props.row)" size="mini"   style="margin-left:14px;">新增</el-button>
          </template>
        </el-table-column>

        <el-table-column prop="foodCount" label="食材数量"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.native="rematchBtn">重新匹配</el-button>
      </span>
    </el-dialog>
    <addProp ref="addDrom" :foodDetailId="foodDetailId"></addProp>
  </div>
</template>

<script>
import {foodDetails,modify ,rematch} from '../../../../request/requrst'
import { Message } from "element-ui";
// import {debounce} from '../../../../directive/index'
import addProp  from './add.vue'
 export default{
    components:{
        addProp
    },
    data(){
        return{
            stateDialogVisible:false,
            textarea:'',
            foodsDetails:[]
        }
    },
    watch:{
        textarea(newVal, oldVal){
            console.log(newVal, oldVal)
        }
    },
    props:['foodDetailId'],
    methods:{formatFoodId(row) {
        if (!row.foodId) {
          return '<span style="color: red">foodId is null</span>';
        } else {
          return row.foodId;
        }
      },
        // 修改
        modifyBtn(item){
            console.log('dfdfdfdf12313131',item)
             this.$refs.addDrom.centerDialogVisible=true
             this.$refs.addDrom.ruleForm.foodName=item.foodName
             this.$refs.addDrom.source()
             this.$refs.addDrom.category()
            // item.ishide=true
            // item.isNot=!item.isNot
            // item.isbtn =!item.isbtn
        },
        modifydis(item){
            item.ishide=false
            item.isNot=!item.isNot
            item.isbtn =!item.isbtn
            let foodsDetails=JSON.parse(JSON.stringify(this.foodsDetails))
            foodsDetails.forEach((item)=>{
               delete item.isbtn
               delete item.isNot
               delete item.ishide
            })

             modify('api/package/track/modify', 'PUT', {packageId: this.foodDetailId,foodsDetails:JSON.parse(JSON.stringify(foodsDetails))}).then((res) => {
               Message({
                type: "success",
                message: res.msg
                });

           }).catch((err) => {
            console.log(err.message)
         })

        },
        //  重心匹配


        rematchBtn(){
            rematch(`api/package/track/rematch?packageId=${this.foodDetailId}`, 'PUT', {}).then((res) => {
               Message({
                type: "success",
                message: res.msg
                });
                this.stateDialogVisible=false
               this.$parent.getList()
               this.$parent.foodOverviewEvent()
           }).catch((err) => {
                console.log(err.message)
            })
        },
        // changeValue:debounce(function(){
        //     this.textarea.sp

        //  }, 1500),
        foodDetailsData(){
             let param ={}
             param.packageId = this.foodDetailId
             foodDetails('api/package/track/foodDetails', 'get', param).then((res) => {
             console.log('sdsdsdsdsd',res)
             this.foodsDetails =res.data.foodsDetails
             console.log('fdfdfdfd你好66666666666666')
            //  this.foodsDetails.forEach((item,index)=>{
            //     if(!item.foodId){
            //         this.$set(item,'isbtn',true)
            //     }else{
            //         this.$set(item,'isbtn',false)
            //     }
            //     this.$set(item,'isNot',false)
            //     this.$set(item,'ishide',false)
            //  })

           }).catch((err) => {
            console.log(err.message)
         })


        }
    }
 }

</script>

<style lang="scss" scoped>
  .stateBox{
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    .textareaBox{
        display: flex;
        align-items: center;
        span{
            width: 90px;
            letter-spacing: 2px;
        }
    }
    .listTitle{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        h3{
            margin-bottom: 20px;
        }
        .itemBOX{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
            margin-bottom: 14px;
            .leftBox {
                display: flex;
                 align-items: center;
                span:first-child{
                margin-right: 10px;
                width: 150px;

                display: inline-block;
            }
            }


            .el-icon-success{
                color: #409eff;
                font-size: 24px;
            }
            .el-icon-error{
                color: #f56c6c;
                font-size: 24px;
            }
        }
    }
  }
</style>
