<template>
    <div class="loginAll">
           <div class="loginBox">
            <h2>包裝後臺登錄</h2>
            <el-form  ref="ruleForm" :model="form"  :rules="rules" label-width="80px" class="imputflex">
                  <el-form-item label="用戶名:" prop="user" >
                    <el-input  v-model="form.user"></el-input>
                  </el-form-item>
                  <el-form-item label="密碼:"  prop="pws">
                    <el-input v-model="form.pws" type="password"  autocomplete="off"></el-input>
                  </el-form-item>

                  <el-form-item>
                        <el-button type="primary" style="width:318px ;" @click="submitForm('ruleForm')">登录</el-button>
                    </el-form-item>
                </el-form>
           </div>
    </div>
  </template>
  
  <script>

  import { requestMethods } from '../../request/requrst'
  import { Message } from "element-ui";
  export default {
    data() {
      return {
        form:{
          user:'',
          pws:''
        },
        rules:{
          user: [
            { required: true, message: '請輸入用戶名', trigger: 'blur' },
          ],
          pws: [
            { required: true, message: '請輸入密碼', trigger: 'blur' }
          ],
        }
      } 
    },
    methods:{
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.getResult()
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      getResult(){
        let param ={}
        param.userName=this.form.user
        param.passWord=this.form.pws
        requestMethods('/api/user/login', 'post', param).then((res) => {
            console.log(res)
             let resData =res.data
             this.$store.commit('activeEvnent',{userId:resData.loginUser.userId,userName:resData.loginUser.userName,token:resData.token})
             Message({
              type: "success",
              message: "登錄成功"
            });
             this.$router.push('/home')
           }).catch((err) => {
            console.log(err.message)
         }) 
      }
    }
  }
  </script>

  <style lang="scss" scoped>
   .loginAll{
     width: 100%;
     height: 100%;
     background-color: #082844;
     display: flex;
     justify-content: center;
     align-items: center;
     .loginBox{
        width: 500px;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        h2{
          margin-bottom: 20px;
          font-size: 28px;
          color: #333333;
        }
        .imputflex{
          width: 400px;
          .inputWidth{
            width: 200px;
            
          }
        }
       
     }
   }
  </style>
  