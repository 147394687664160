import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser:{
      userId:sessionStorage.getItem('userId')|| '',
      userName:sessionStorage.getItem('userName')|| ''
    },
    token:sessionStorage.getItem('token')|| '',
    currentMenu: sessionStorage.getItem('currentMenu')?sessionStorage.getItem('currentMenu'):'/home/hot'
   
  },
  getters: {
  },
  mutations: {
    
    activeEvnent(state,data){
 
      state.loginUser.userId=data.userId
      state.loginUser.userName=data.userName
      state.token=data.token
      sessionStorage.userId=data.userId
      sessionStorage.userName=data.userName
      sessionStorage.token=data.token
    },
    menuEvnent(state,data){
      state.currentMenu=data
      sessionStorage.currentMenu=data
    }

  },
  actions: {
  },
  modules: {
  }
})
