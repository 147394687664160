<template>
  <div class="card-wrapper">
    <!--    標題-->
    <div class="hTop">
      <h2>All列表</h2>
    </div>
    <!--    過濾信息-->
    <div class="form">
      <!-- 前進後退操作按鈕-->
      <div class="undoRedoButton">
        <div class="el-button-group" style="min-width: 120px">
          <el-button type="primary" :disabled="hiddenCards.length === 0" size="mini" round
                     @click="undoHide">回退
          </el-button>
          <el-button type="primary" :disabled="shownCards.length === 0" size="mini" round
                     @click="redoHide">前進
          </el-button>
        </div>
        <!-- 訂單號輸入框-->
      </div>
      <div class="input-box">
        <label class="demonstration">訂單號：</label>
        <el-input
            placeholder="請輸入訂單號"
            v-model="inputBookingNo"
            clearable>
        </el-input>
      </div>
      <!-- 訂單送貨日期-->
      <div class="el-date-picker">
        <span class="demonstration">送貨日期：</span>
        <el-date-picker
            :clearable="true"
            v-model="deliverTimeBind"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            @change="listPackagesByDeliverTime">
        </el-date-picker>
      </div>
      <el-button type="primary" size="mini" round style="margin-left:15px;margin-top: 5px; height:30px ;"
                 @click="filterData">查詢
      </el-button>
      <el-button type="primary" size="mini" round style="margin-left:15px;margin-top: 5px; height:30px ;"
                 @click="exportData">導出
      </el-button>
    </div>
    <!--    食材信息標籤-->
    <div class="selectedBookingNoTag">
      <div v-for="(order) in this.checkOrdersByDeliverTime"
           :key="order"
           class="orderTag">
        <el-tag
            effect="dark"
            type='warning'
            hit
            style="min-width: 120px;color: black; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
          {{ order }}
        </el-tag>
      </div>
    </div>
    <div class="selectAllFoodTagButton">
      <div class="el-button-group ">
        <el-button type="primary" size="mini" round
                   @click="selectAllFoodTag('true')">標籤全選
        </el-button>
        <el-button type="primary" size="mini" round
                   @click="selectAllFoodTag('false')">標籤全不選
        </el-button>
      </div>
    </div>
    <!--    食材信息標籤-->
    <div class="defineCategoryTag">
      <div v-for="(defineCategory,index) in defineCategoryInfo()"
           :key="defineCategory"
           class="foodTag">
        <el-tag
            effect="dark"
            :type="getTagStyle(defineCategory)"
            hit
            style="min-width: 120px;color: black; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
            @click="handleClickFoodTag(index,defineCategory)">
          {{ defineCategory }}
        </el-tag>
      </div>
    </div>
    <!--    加載數據樣式-->
    <div class="loading" v-if="isLoading">加載中...</div>
    <!--    食材信息卡片-->
    <div class="content-list">
      <div class="card" v-if="!isHidden(item.foodId) && !isHiddenType(item.defineCategory)" v-for="(item, index) in items"
           :key="index"
           :style="{ backgroundColor: item.color }"
           @click="hideCard(item.foodId)">
        <div class="card-text">
          <div style="font-weight: bold;font-size: 22px">{{ item.foodName }}</div>
          <div style="font-size: 18px">食材數量: <span style="font-weight: bold"
                                                       :style="{color: item.foodCount>1?'red':'black'}">{{
              item.foodCount
            }}</span></div>
          <div style="font-size: 16px">{{ item.defineCategory }}</div>
        </div>
      </div>
    </div>
    <!-- 送貨日期選擇訂單彈窗-->
    <div>
      <el-dialog
          title="請選擇訂單號"
          :visible.sync="dialogVisibleByDeliverTime"
          width="75%"
          center
          @close="handleCloseByDeliverTime()">
        <div>
          <el-checkbox :indeterminate="selectAllFlagByDeliverTime" v-model="selectAllByDeliverTime"
                       @change="handleSelectAllChangeByDeliverTime">選擇所有訂單號
          </el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checkOrdersByDeliverTime" @change="handleSelectOrdersChangeByDeliverTime"
                             class="checkbox-group">
            <el-checkbox v-for="orderId in orderListsByDeliverTime" :label="orderId" class="checkbox-item">{{
                orderId
              }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseByDeliverTime('cancel')">取 消</el-button>
          <el-button type="primary" @click="handleCloseByDeliverTime('confirm')">確 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {overview, packageTrack} from '../../request/requrst'
import axios from "axios";
import {Message} from "element-ui";

export default {
  // 處理離開當前頁
  beforeRouteLeave(to, from, next) {
    // 如果token为空 直接路由到 root
    if (sessionStorage.getItem('token') === null) {
      next();
      return
    }
    const confirm = window.confirm('確定離開當前頁嗎?');
    if (confirm) {
      next();
    } else {
      next(false);
    }
  },
  data() {
    return {
      dialogVisibleByDeliverTime: false,
      // 記錄食材標籤是否被點擊，結構為('string','boolean')
      foodTagsDisabled: new Map,
      // 送貨時間
      deliverTimeBind: [],
      // 訂單編號
      inputBookingNo: '',
      // 卡片展示數組
      items: [],
      // 訂單時間綁定
      timeBind: [],
      // 全屏
      isFullscreen: false,
      // 是否显示加載動畫
      isLoading: true,
      // 訂單選擇彈窗
      dialogVisible: false,
      // 訂單信息
      packages: [],
      // 彈出的訂單選擇是否全選by送貨日期
      selectAllByDeliverTime: false,
      // 選擇的訂單by送貨日期
      checkOrdersByDeliverTime: [],
      // 選擇框訂單是否全選by送貨日期
      selectAllFlagByDeliverTime: true,
      // 選擇的訂單列表by送貨日期
      orderListsByDeliverTime: [],
      // 隱藏的食材標籤
      hiddenTypes: [],
      // 存儲隱藏卡片的foodId
      hiddenCards: [],
      // 存儲显示卡片的foodId
      shownCards: [],
    }
  },
  created() {
    this.overView()
  },
  computed: {
    // 計算卡片排列
    cardCount() {
      return Math.ceil(this.items.length / 4)
    }
  },
  methods: {
    // 處理食材標籤全選
    selectAllFoodTag(val) {
      if (val === 'true') {
        // 全選
        this.hiddenTypes = []
        this.foodTagsDisabled = new Map
      } else if (val === 'false') {
        // 全不選
        const defineCategoryInfo = this.defineCategoryInfo()

        defineCategoryInfo.forEach(value => {
          this.hiddenTypes.push(value)
          this.foodTagsDisabled.set(value, true)
        })
      }
      this.$forceUpdate()
    },
    // 初始化是否置灰標籤Map 默認全false
    initFoodTagsDisabled() {
      this.defineCategoryInfo().forEach(value => {
        this.foodTagsDisabled.set(value, false);
      })
    },
    // 獲取標籤樣式
    getTagStyle(defineCategory) {
      if (this.foodTagsDisabled.get(defineCategory)) {
        return "info";
      } else {
        return "success";
      }
    },
    // 處理標籤點擊事件
    handleClickFoodTag(index, defineCategory) {
      // 變更按鈕樣式
      this.foodTagsDisabled.set(defineCategory, !this.foodTagsDisabled.get(defineCategory));
      // 修改item，過濾掉點擊的標籤
      // 獲取當前的標籤的是否置灰flag
      const flag = this.foodTagsDisabled.get(defineCategory)
      if (flag) {
        this.hiddenTypes.push(defineCategory)
      } else {
        this.hiddenTypes = this.hiddenTypes.filter(fd => fd !== defineCategory)
      }
      this.$forceUpdate()
    },
    // 計算集合中現存的標籤
    defineCategoryInfo() {
      const foodAbbas = []
      if (this.hiddenCards.length > 0) {
        const filter = this.items.filter(obj => !this.hiddenCards.some(id => id === obj.foodId));
        filter
            .forEach(function (value) {
              foodAbbas.push(value.defineCategory)
            })
        return Array.from(new Set(foodAbbas)).sort((a, b) => {
          if (a === '' || a === null || a === 'undefined') return 1; // 將空元素放在最後面
          if (b === '' || b === null || b === 'undefined') return -1;
          return a.localeCompare(b); // 按首字符進行排序
        });
      } else {
        // 所有食材信息
        this.items.forEach(function (value) {
          foodAbbas.push(value.defineCategory)
        })
        return Array.from(new Set(foodAbbas)).sort((a, b) => {
          if (a === '' || a === null || a === 'undefined') return 1; // 將空元素放在最後面
          if (b === '' || b === null || b === 'undefined') return -1;
          return a.localeCompare(b); // 按首字符進行排序
        });
      }
    },
    // 隱藏卡片
    hideCard(foodId) {
      this.hiddenCards.push(foodId);
      this.shownCards = this.shownCards.filter(idx => idx !== foodId);
    },
    //卡片屬性是否隱藏
    isHidden(foodId) {
      return this.hiddenCards.includes(foodId);
    },
    // tag屬性是否置灰
    isHiddenType(defineCategory) {
      return this.hiddenTypes.includes(defineCategory);
    },
    // 後退操作
    undoHide() {
      if (this.hiddenCards.length > 0) {
        const lastHiddenIndex = this.hiddenCards.pop();
        this.shownCards.push(lastHiddenIndex);
      }
    },
    // 前進操作
    redoHide() {
      if (this.shownCards.length > 0) {
        const lastShownIndex = this.shownCards.pop();
        this.hiddenCards.push(lastShownIndex);
      }
    },
    // 處理送貨日期訂單信息全選操作
    handleSelectAllChangeByDeliverTime(val) {
      this.checkOrdersByDeliverTime = val ? this.orderListsByDeliverTime : [];
      this.selectAllFlagByDeliverTime = false;
    },
    // 處理訂單信息送貨日期選擇操作
    handleSelectOrdersChangeByDeliverTime(value) {
      let checkedCount = value.length;
      this.selectAllByDeliverTime = checkedCount === this.orderListsByDeliverTime.length;
      this.selectAllFlagByDeliverTime = checkedCount > 0 && checkedCount < this.orderListsByDeliverTime.length;
    },
    // 送貨日期選擇后彈框
    handleCloseByDeliverTime(val) {
      this.dialogVisibleByDeliverTime = false
      if (val === 'confirm') {
        this.overView()
      }
      if (val === 'cancel') {
        this.checkOrdersByDeliverTime = []
      }
      this.orderListsByDeliverTime = []
      this.selectAllByDeliverTime = false
    },
    // 獲取所有符合條件包裝信息(送貨時間)
    listPackagesByDeliverTime() {
      if (this.deliverTimeBind) {      // 加載時間段內訂單信息
        this.packageTrack()
        this.dialogVisibleByDeliverTime = true
      }
      this.checkOrdersByDeliverTime = []
    },
    // 獲取包裝/訂單信息
    packageTrack() {
      let param = {}
      // 狀態(1正在包裝 2包裝完成 3已經車走 4未匹配 5取消)
      param.status = 1;
      // 選擇訂單產生日期時候
      // 選擇訂單送貨日期時候
      if (this.deliverTimeBind) {
        param.deliverStartTime = this.deliverTimeBind[0];
        param.deliverEndTime = this.deliverTimeBind[1];
      }
      param.pageNum = 1;
      param.pageSize = 10000;
      const _this = this;
      packageTrack('/api/package/track/list', 'get', param).then((res) => {
        this.orderListsByDeliverTime = []
        _this.packages = res.data.rows
        _this.packages.forEach(function (val) {
          _this.orderListsByDeliverTime.push(val.deliveryTime.match(/\d{2}:\d{2}:\d{2}/) + "|" + val.bookingNo)
        })
        _this.orderListsByDeliverTime.sort()
      }).catch((err) => {
        console.log(err.message)
      })
    },
    handleBookingNos(val) {
      const orderIds = []
      val.forEach(value => {
        orderIds.push(value.split("|")[1])
      })
      return orderIds;
    },
    // 食材信息總覽
    overView() {
      this.hiddenTypes = []
      this.hiddenCards = []
      this.shownCards = []
      let param = {}
      param.bookingNo = this.inputBookingNo;
      // 選擇訂單送貨日期時候
      if (this.deliverTimeBind) {
        param.deliverStartTime = this.deliverTimeBind[0];
        param.deliverEndTime = this.deliverTimeBind[1];
      }
      // 直接指定為最後選擇的訂單信息
      param.bookingNos = this.handleBookingNos(this.checkOrdersByDeliverTime);

      this.isLoading = true;
      overview('/api/process/overview/hot', 'post', param).then((res) => {
        this.items = res.data.overview
        this.isLoading = false;
      }).catch((err) => {
        console.log(err.message)
      })
      this.initFoodTagsDisabled()
    },
    // 點擊卡片變色
    changeColor(item) {
      let colorValue = item.color;
      if (colorValue === undefined || colorValue === 'lightgreen') {
        this.$set(item, 'color', 'white')
      } else if (colorValue === 'white') {
        this.$set(item, 'color', 'lightgreen')
      }
    },
    // 過濾數據
    filterData() {
      this.overView(3);
    },
    exportData() {
      let data = {}
      data.bookingNo = this.inputBookingNo;
      // 選擇訂單送貨日期時候
      if (this.deliverTimeBind
      ) {
        data.deliverStartTime = this.deliverTimeBind[0];
        data.deliverEndTime = this.deliverTimeBind[1];
      }
      // 直接指定為最後選擇的訂單信息/api/process/overview/Seafood/export
      data.bookingNos = this.handleBookingNos(this.checkOrdersByDeliverTime);
      axios.post(process.env.VUE_APP_BASE_URL + '/api/process/overview/hot/export', data, {
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('token')
        }
      }).then(res => {
        const responseContentType = res.headers['content-type'];
        if (responseContentType.includes('application/json')) {
          // Convert Blob to text
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsText(res.data);
          }).then(jsonString => {
            // Parse text as JSON
            try {
              const data = JSON.parse(jsonString);
              if (data.code === 500) {
                Message.error(data.msg);
              }
              // Proceed with other JSON-related processing
            } catch (error) {
              console.error('JSON parsing error', error);
            }
          }).catch(error => {
            console.error('Text conversion error', error);
          });
        } else {
          console.log('headers',res.headers)
          // Handle non-JSON response, such as Blob (Excel file)
          let blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'});
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = decodeURIComponent(res.headers['content-disposition']);
          a.click();
          // Release the temporary URL object
          window.URL.revokeObjectURL(url);
        }
      }).catch(error => {
        console.error('Request failed', error);
      });
    }
  },
  setWrapperBorder() {
    this.$refs.wrapper.style.border = '1px solid #ccc'
  },
  mounted() {
    this.initFoodTagsDisabled()
  },
  updated() {
    // this.setWrapperBorder()
  }
}

</script>

<style scoped>
.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -5px;
  padding: 1rem; /* 圍起來的框內邊距 */
  border: 1px solid #ccc;
  background-color: #eee;
  border-radius: 15px;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.form {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 15px;
}

.defineCategoryTag {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selectedBookingNoTag {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.foodTag {
  margin-bottom: 5px;
}

.orderTag {
  margin-bottom: 5px;
}

.content-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  width: calc(25% - 10px);
  height: 150px;
  background-color: lightgreen;
  color: #212121;
  margin: 5px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 2px 2px 10px 0 rgba(47, 79, 79, 0.5);
}

.card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.el-date-picker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}

.input-box {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 200px;
}

.demonstration {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  font-weight: bold;
}

.hTop {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.selectOrders /deep/ .el-checkbox {
  width: 15%;
  height: 40px;
  line-height: 40px;
}

.selectOrders .el-checkbox /deep/ .el-checkbox__label {
  font-size: 20px;
}

.undoRedoButton {
  margin-right: 20px;
  margin-top: 6px;
  display: flex;
}

.selectAllFoodTagButton {
  display: flex;
  margin-bottom: 15px;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

</style>
