<template>
  <div class="navBox">
    <el-container>

      <el-aside :class="isCollapse?'add-el-aside':'el-asideBox'">
        <div class="topBox" :class="isCollapse?'add-el-aside':'el-asideBox'">
          <img src="../assets/img/box.png" alt="">
          <span v-if="!isCollapse">包裝分發系統</span>
        </div>
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            :collapse="isCollapse"
            background-color="#082844"
            text-color="#fff"
            :default-active="$store.state.currentMenu"
            router
            active-text-color="#ffd04b">
          <!-- <el-menu-item index="4">
              <i class="el-icon-setting"></i>
              <span slot="title">統計展示</span>
          </el-menu-item> -->



          <div @click="isCollapseBtn" class="isCollapseBtn">
            <i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'"></i>
          </div>
          <div style="margin-bottom: 15px"></div>
          <el-submenu index="/home/All">
            <template slot="title">
              <i class="el-icon-view"></i>
              <span slot="title">加工看板</span>
            </template>
            <template>
              <el-menu-item index="/home/All" @click="clickMenu('/home/All')">All</el-menu-item>
              <el-menu-item index="/home/hot" @click="clickMenu('/home/hot')">hot</el-menu-item>
              <el-menu-item index="/home/Others" @click="clickMenu('/home/Others')">Others</el-menu-item>
              <el-menu-item index="/home/Seafood" @click="clickMenu('/home/Seafood')">Seafood</el-menu-item>
              <el-menu-item index="/home/Cold" @click="clickMenu('/home/Cold')">Cold</el-menu-item>
              <el-menu-item index="/home/Unused" @click="clickMenu('/home/Unused')">Unused</el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item index="/home/package" @click="clickMenu('/home/package')">
            <i class="el-icon-s-promotion"></i>
            <span slot="title">包裝追蹤</span>
          </el-menu-item>
          <!-- 加工看板 -->


          <!-- <el-menu-item index="/home/consume"  @click="clickMenu('/home/consume')">食材商品管理</el-menu-item> -->

          <el-submenu index="/home/consume" v-if="$store.state.loginUser.userName=='admin'">
            <template slot="title">
              <i class="el-icon-picture-outline-round"></i>
              <span slot="title">食材商品管理</span>
            </template>
            <template>
              <el-menu-item index="/home/consume" @click="clickMenu('/home/consume')">食材商品列表</el-menu-item>
              <el-menu-item index="/home/inventory" @click="clickMenu('/home/inventory')">盤點</el-menu-item>
              <el-menu-item index="/home/distribution" @click="clickMenu('/home/distribution')">補貨</el-menu-item>
            </template>
          </el-submenu>

          <!-- <el-menu-item index="/home/inventory"  @click="clickMenu('/home/inventory')">食材庫存管理</el-menu-item> -->

          <el-menu-item index="/home/adduser" @click="clickMenu('/home/adduser')"
                        v-if="$store.state.loginUser.userName=='admin'">
            <i class="el-icon-user-solid"></i>
            <span slot="title">添加管理員</span>
          </el-menu-item>

          <el-menu-item>
            <!-- <i class="el-icon-thumb" style="color:"></i> -->
            <!-- <span   >退出</span> -->
            <el-button slot="title" type="primary" size="medium" style="width:100%; font-size: 15px;"
                       @click.native="returnBtn">退出
            </el-button>
          </el-menu-item>
          <div class="fullScreen">
            <i class="el-icon-full-screen" style="font-size: 30px;margin-bottom: 10px;margin-top: 10px;color: white" @click="handleScreen"
               title="全屏"></i>
          </div>
          <div style="margin-bottom: 15px"></div>
        </el-menu>

      </el-aside>
      <el-container style="background:#F5F5F5">
<!--        <el-header>



          <div class="headerBox">
            <i class="el-icon-full-screen" style="font-size: 30px;margin-right:20px" @click="handleScreen"
               title="全屏"></i>
            &lt;!&ndash; <el-input
            style="width:380px;margin-right: 20px;"
            placeholder="Search"
            v-model="search">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input> &ndash;&gt;
            <img src="../assets/img/userImg.png" alt="" class="userBox">
            <el-dropdown class="dropdownBox">
                <span class="el-dropdown-link">
                  {{ $store.state.loginUser.userName }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="returnBtn">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            &lt;!&ndash; <i class="el-icon-chat-line-square icoBox" ></i>
            <i class="el-icon-message-solid icoBox" ></i> &ndash;&gt;

          </div>
        </el-header>-->
        <el-main class="mainBox">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>

</template>
<script>
import {logout} from '../request/requrst'
import screenfull from "screenfull";

export default {
  data() {
    return {
      search: '',
      isCollapse: false
    }
  },
  mounted() {
    this.activeIndex = this.$route.path
  },
  methods: {
    handleScreen() {
      if (!screenfull.isEnabled) {
        return false
      }
      screenfull.toggle()
    },
    clickMenu(item) {
      this.$store.commit('menuEvnent', item)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    isCollapseBtn() {
      this.isCollapse = !this.isCollapse

    },
    returnBtn() {
      let param = {}
      logout('/api/user/logout', 'post', param).then((res) => {
        console.log(res)
        sessionStorage.clear()
        this.$router.push('/')
        window.location.reload()
      }).catch((err) => {
        console.log(err.message)
      })


    }
  }
}
</script>
<style lang="scss">
.el-container, .navBox {
  width: 100%;
  display: flex;
  height: 100%;

  .el-aside {
    width: 100%;
    height: 100%;
    background-color: #082844;

    .el-menu-item, .el-submenu {
      text-align: left !important;
    }

    .el-menu {
      width: 100%;
    }

    .el-submenu__title i {
      color: #fff;
    }

    .el-icon-arrow-down:before {
      content: "\e790";
      font-size: 18px;
    }

    .topBox {
      display: flex;
      height: 70px;
      width: 200px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-top: 10px;
      }

      span {
        color: #fff;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}

.el-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-content: space-between;
}

.headerBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .userBox {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }

  .dropdownBox {
    margin: 0 5px;
  }

  .icoBox {
    font-size: 26px;
    margin-right: 10px;
    color: #082844;
    cursor: pointer;
  }

  .icoBox:last-child {
    margin-right: 0 !important;
  }

  .el-icon-arrow-down:before {
    content: "\e790";
    font-size: 18px;
  }
}

// .mainBox{
//   padding-top: 60px !important;
// }

.add-el-aside {
  width: 65px !important;
  transition: all 0.5s;
}

.el-asideBox {
  width: 200px !important;
  // transition: all 0.5s;
}

.isCollapseBtn {
  cursor: pointer;
  color: white;
  font-size: 30px;
  .el-icon-s-fold, .el-icon-s-unfold {
    font-size: 28px;
  }
}
.fullScreen {
  cursor: pointer;
  color: white;
  .el-icon-s-fold, .el-icon-s-unfold {
    font-size: 28px;
  }
}

</style>
  