<template>
<!--    <div>
        <el-dialog
            title="正在包装"
            :visible.sync="processDialogVisible"
            width="600px"
            style="text-align: left;"
            >
            <div class="stateBox">
                &lt;!&ndash; <div class="textareaBox">
                    <span>id匹配:</span>
                    <el-input
                    type="textarea"
                    :rows="4"
                    v-focus
                    ref="saveTagInput"
                    placeholder="請輸入商品id用逗號隔開"
                    v-model="textarea">
                   </el-input>
                </div>    &ndash;&gt;

                <div class="listTitle">
                     <h3>食材列表</h3>
                    <div class="itemBOX" v-for="(item,index) in newArr4" :key="item.foodId">
                        <div class="leftBox">
                            <span>{{item.foodId}}</span>
                            <span>{{item.foodName}}</span>
                        </div>


                           <i class="el-icon-success" v-if="item.ister"></i>
                    </div>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                &lt;!&ndash; <el-button type="primary" :disabled="newArr4.some( item => item.ister===true?false:true )"  @click.native="rematchBtn">包装完成</el-button> &ndash;&gt;
                <el-button type="primary"   @click.native="rematchBtn">包装完成</el-button>
            </span>
            </el-dialog>
    </div>-->

  <div>
    <el-dialog
        title="正在包装"
        :visible.sync="processDialogVisible"
        width=45%
        style="text-align: center;top: 5px"
    >
      <h3>食材列表</h3>
      <el-table :data="this.foodsDetails">
        <el-table-column prop="foodId" label="食材ID">
          <template slot-scope="{ row }">
            <span :style="{ color: row.foodId ? '' : 'red' }">{{ row.foodId || '食材ID未匹配' }}</span>
          </template>
        </el-table-column>
        <el-table-column  label="食材名称">
          <template slot-scope="props">
            {{ props.row.foodAlias ? props.row.foodName + '|' + props.row.foodAlias : props.row.foodName }}
            <el-button type="primary" v-if="!props.row.foodId"  @click.native="modifyBtn(props.row)" size="mini"   style="margin-left:14px;">新增</el-button>
          </template>
        </el-table-column>

        <el-table-column prop="foodCount" label="食材数量"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
       <el-button type="primary"   @click.native="rematchBtn">包装完成</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>

import { Message } from "element-ui";
import {foodDetails,handle} from '../../../../request/requrst'
import {debounce} from '../../../../directive/index'
import addProp from "@/views/reportForms/package/child/add.vue";
 export default{
   components: {addProp},
    data(){
        return{
            processDialogVisible:false,
            textarea:'',
            foodsDetails:[],
            disabledDIS:true,
            newValData:[],
            newArr4:[],
            newVal:true,
            dihisfale:true
        }
    },
    directives: {
        focus: {
            inserted (el) {
                el.focus()
                el.children[0].focus()
                setTimeout(_ => {
                  el.children[0].focus()
                })
          }
      }
    },
    watch:{

        processDialogVisible(val, value){
            if(val===false){
                this.textarea=''
            }
        },
        textarea(newVal, oldVal){
            console.log(newVal, oldVal)
           this.newValData= newVal.replace(/[\uff0c]/g, ",").split(',').map(Number)
           let newArr2 = this.foodsDetails.filter(i => {
              if(this.newValData.indexOf(i.foodId) > -1){
                    i.ister=true
               return  i
              }
           })

           let newArr3 = this.foodsDetails.filter(i => {
            if(this.newValData.indexOf(i.foodId) == -1){
                    i.ister=false
               return  i
              }
           })
            this.newArr4= [...newArr2,...newArr3]
        }
    },
    props:['foodDetailId'],
    methods:{
        //  包装完成
      rematchBtn() {
        if (confirm("確認變更為包裝完成狀態嗎?")) {
          let param = {}
          param.packageId = this.foodDetailId
          param.handleType = 2
          handle('api/package/track/handle', 'PUT', param).then((res) => {
            console.log('sdsdsdsdsd', res)
            this.processDialogVisible = false
            Message({
              type: "success",
              message: res.msg
            });
            this.$parent.getList()
            this.$parent.foodOverviewEvent()
          }).catch((err) => {
            console.log(err.message)
          })
        }
      },
        foodDetailsData(){
             let param ={}
             param.packageId = this.foodDetailId
             foodDetails('api/package/track/foodDetails', 'get', param).then((res) => {
             console.log('sdsdsdsdsd',res)
             this.foodsDetails =res.data.foodsDetails || []
             this.newArr4 =  this.foodsDetails
             this.foodsDetails.forEach((item,index)=>{
              this.$set(item,'ister',false)
             })
           }).catch((err) => {
            console.log(err.message)
         })


        }
    }
 }

</script>

<style lang="scss" scoped>
  .stateBox{
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    .textareaBox{
        display: flex;
        align-items: center;
        span{
            width: 90px;
            letter-spacing: 2px;
        }
    }
    .listTitle{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        h3{
            margin-bottom: 20px;
        }
        .itemBOX{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
            margin-bottom: 14px;
            .leftBox {
                display: flex;
                 align-items: center;
                span:first-child{
                margin-right: 10px;
                width: 150px;

                display: inline-block;
            }
            }


            .el-icon-success{
                color: #409eff;
                font-size: 24px;
            }
            .el-icon-error{
                color: #f56c6c;
                font-size: 24px;
            }
        }
    }
  }
</style>
