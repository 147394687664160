<template>
  <div class="reportFormsBox">

    <div class="reportList">
      <h2>食材消耗系統</h2>
      <div class="flexBox">
        <div class="itemBox">
          <div class="left">
            <span>缺貨食材</span>
            <h2>{{foodOverviewData.lack}}</h2>
          </div>
          <img src="../../../assets/img/ico_a.jpg" alt="">

        </div>
        <div class="itemBox">
          <div class="left">
            <span>食材數量緊張</span>
            <h2>{{foodOverviewData.warning}}</h2>
          </div>
          <img src="../../../assets/img/ico_b.jpg" alt="">

        </div>
        <div class="itemBox">
          <div class="left">
            <span>庫存充裕</span>
            <h2>{{foodOverviewData.profusion}}</h2>
          </div>
          <img src="../../../assets/img/ico_c.jpg" alt="">

        </div>
      </div>
    </div>

    <div class="tableBox">
      <h2>
        食材追蹤
      </h2>

      <div class="tableList">
        <div class="leftTitle">
                <span :class="activeIndex===item.id?'active':''" v-for="item in tabTitle" :key="item.id" @click="activeBtn(item)">
                      {{item.name}}
                 </span>
        </div>
        <div class="rightInput">
          <el-input placeholder="请输入内容" v-model="foodNo" class="input-with-select" clearable @clear="handleClear">
            <el-select v-model="select" slot="prepend" placeholder="请选择"  style="width:150px;">
              <el-option  label="商品編號" value="1"></el-option>
              <el-option label="商品名稱" value="2"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click.native="searchBtn"></el-button>
          </el-input>
          <el-button    type="primary" size="mini" round style="margin-left:14px; height:30px ;" @click.avitve="addBtn" >新增</el-button>
          <!-- <el-button    type="primary" size="mini" round style="margin-left:14px; height:30px ;" @click.avitve="syncBtn" >同步</el-button> -->
          <el-button    type="primary" size="mini" round style="margin-left:14px; height:30px ;" @click.antive="exportExcel()">导出</el-button>
        </div>

      </div>

      <tableBase
          size='mini'
          :isSelection='false'
          :isIndex='false'
          :isPagination='true'
          :isHandle='true'
          :tableData='todayData.tableData'
          :tableCols='todayData.tableCols'
          :pagination='todayData.pagination'
          @handleCurrentChange='handleCurrentChange'
      >
      </tableBase>

      <!-- 新增 -->

      <el-dialog
          :title="title"
          style="text-align:left;"
          :visible.sync="centerDialogVisible"
          width="600px">
        <div class="loginBox">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <!-- <el-form-item label="商品編號" prop="foodNo">
              <el-input v-model="ruleForm.foodNo" :disabled="disabledBt"></el-input>
            </el-form-item> -->
            <el-form-item label="商品名稱" prop="foodName">
              <el-input v-model="ruleForm.foodName" :disabled="disabledBt"></el-input>
            </el-form-item>

            <el-form-item label="商品分類" prop="defineCategory">
              <el-select v-model="ruleForm.defineCategory" placeholder="请选择" style="width:100% ;">
                <el-option
                    v-for="item in categoryArray"
                    :key="item.defineCategoryId"
                    :label="item.defineCategoryName"
                    :value="item.defineCategoryName">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="所屬部門" prop="department">
              <el-select v-model="ruleForm.department" placeholder="请选择" style="width:100% ;">
                <el-option
                    v-for="item in departmentArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="商品別名" >
              <el-input v-model="ruleForm.foodAbbr"></el-input>
            </el-form-item>

            <el-form-item label="商品來源">

              <el-select v-model="ruleForm.source" placeholder="请选择" style="width:100% ;">
                <el-option
                    v-for="item in sourceArray"
                    :key="item.foodSourceId"
                    :label="item.foodSourceName"
                    :value="item.foodSourceName">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品預警值">
              <el-input v-model="ruleForm.warningCount"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">創建</el-button>
              <el-button @click="resetForm">取消</el-button>
            </el-form-item>
          </el-form>
        </div>

      </el-dialog>



      <!-- end -->

    </div>
    <el-dialog
        title="添加別名"
        style="text-align:left !important"
        :visible.sync="aliasDialog"
        width="30%"
        left>
      <div class="aliasBox">
        <span>商品別名：</span>
        <el-input v-model="aliasInput" placeholder="請輸入內容"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="aliasBtn('cancel')">取 消</el-button>
            <el-button type="primary" @click="aliasBtn('confirm')">确 定</el-button>
          </span>
    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { Message } from "element-ui";
import { foodList ,foodSave,foodUpdate,foodOverview,foodData,syncData,sourceData,categoryData,aliasData} from '../../../request/requrst'
import tableBase from '../../../components/tbale.vue'
export default {
  components:{
    tableBase
  },
  data(){
    return{
      foodNo: '',
      select: '2',
      activeIndex:1,
      status:'',
      aliasDialog:false,
      foodIdAlias:'',
      aliasInput:'',
      centerDialogVisible:false,
      title:'新增食材',
      disabledBt:false,
      foodOverviewData:{
        lack:'',
        profusion:'',
        warning:''
      },
      departmentArray:[{
        value: 'hot',
        label: 'hot'
      }, {
        value: 'Others',
        label: 'Others'
      }, {
        value: 'Seafood',
        label: 'Seafood'
      }, {
        value: 'Cold',
        label: 'Cold'
      }, {
        value: 'Unused',
        label: 'Unused'
      }],
      sourceArray:[],
      categoryArray:[],
      ruleForm: {
        foodNo: '', // 商品编号
        foodName: '', // 商品名称
        defineCategory:'', // 商品分类
        department:'hot', // 所属部门
        foodAbbr:'', // 商品别名
        source:'', // 商品来源
        warningCount: '', // 商品预警值


      },
      rules:{
        foodNo: [
          { required: true, message: '請輸入商品編號', trigger: 'blur' },
        ],
        foodName: [
          { required: true, message: '請輸入商品名稱', trigger: 'blur' }
        ],
        defineCategory: [
          { required: true, message: '請輸入商品分類', trigger: 'blur' }
        ],
        department: [
          { required: true, message: '請輸入所屬部門', trigger: 'change' }
        ]
      },
      tabTitle:[
        {
          id:1,
          name:'所有食材',
          status:''
        },
        {
          id:2,
          name:'缺貨食材',
          status:0
        },
        {
          id:3,
          name:'數量緊張食材',
          status:2
        },
        {
          id:4,
          name:'充裕食材',
          status:1
        }
      ],
      todayData:{
        tableData:[],
        tableCols:[
          {
            label:'商品編號',
            prop:'foodNo'
          },
          {
            label:'商品名稱',
            prop:'foodName'
          },
          {
            label:'目前庫存數量',
            prop:'stockCount',
            sortable:true,
            align:'center'
          },
          {
            label:'商品別名',
            prop:'foodsDetails',
            type:'Button',
            align:'center',
            width:'450',
            render: (h, params) => {
              let tag = params.row.foodTrackAliases;
              return h("div", [
                tag.map(function (items,index){
                  return h("el-tag", {style:{marginRight:'10px',marginBottom:'6px'}},items.foodAlias)
                }),
                h("el-button", {props: {size:"small"}, on:{
                    click: () => {
                      this.aliasItem(params.row)
                    }
                  }},"添加别名")
              ])
            }
          },
          {
            label:'操作',
            type:'ButtonT',
            width:80,
            render: (h, params) => {
              return (
                  <div>
                    <el-button type='text' size='mini' class='renderText' nativeOnClick={() => this.editItem(params.row)}>
                      修改
                    </el-button>
                  </div>
              )
            }
          }
        ],
        pagination:{
          page:1,//分页数
          limit:10,//每页条数
          total:0//总条数
        },
        foodNo:'',
        foodId:'',
      }

    }
  },
  computed:{
    baseUrlData(){
      return process.env.VUE_APP_BASE_URL+'/api/food/export'
    }
  },
  watch:{
    centerDialogVisible(val, value){
      if(val===false){
        console.log(this.ruleForm)
        Object.keys(this.ruleForm).forEach(key => (this.ruleForm[key] = ''));
        this.foodId=''
        this.ruleForm.department='hot'
      }
    },

    aliasDialog(val, value){
      if(val===false){
        this.aliasInput=''
        this.foodIdAlias =''
      }
    }



  },
  methods: {
    handleClear(){
      this.getList()
    },
    //  同步

    // syncBtn(){
    //   syncData('api/opencart/sync', 'get', {}).then((res) => {
    //        Message({
    //         type: "success",
    //         message: res.msg
    //       });
    //        this.getList()
    //      }).catch((err) => {
    //       console.log(err.message)
    //    })

    // },
    aliasItem(item){
      console.log(item)
      if(!item.foodsDetails){
        this.aliasDialog=true
        this.foodIdAlias = item.foodId
        return
      }
    },
    aliasBtn(val){
      if (val === 'cancel') {
        this.aliasDialog = false
        return
      }
      if(!this.aliasInput){

        return  Message({
          type: "error",
          message: '商品別名不能為空'
        });
      }

      let param ={}
      param.foodId = this.foodIdAlias
      param.foodAlias=this.aliasInput
      aliasData('api/food/alias', 'post', param).then((res) => {

        this.aliasDialog=false
        Message({
          type: "success",
          message: res.msg
        });
        this.getList()
      }).catch((err) => {
        console.log(err.message)
      })


    },
    exportExcel(){
      var params={}
      if(this.select==1){
        params.foodNo=this.foodNo
      }
      if(this.select==2){
        params.foodName=this.foodNo
      }
      if(this.status!==''){
        params.status=this.status
      }
      axios.get(this.baseUrlData,{
        params: params,
        responseType: 'blob',
        headers:{
          'Authorization': 'Bearer'+ ' '+sessionStorage.getItem('token')
        }   //首先设置responseType字段格式为 blob
      }).then(res => {
        console.log('sdsdsdsd',res);
        let blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"}); // 为blob设置文件类型，这里以.xlsx为例
        let url = window.URL.createObjectURL(blob); // 創建一个临时的url指向blob对象
        let a = document.createElement("a");
        a.href = url;
        a.download = decodeURIComponent(res.headers['content-disposition']);
        a.click();
        // 释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },

    addBtn(){
      this.source()
      this.category()
      this.centerDialogVisible=true
      this.title ='新增'
      this.disabledBt=false

    },
    editItem(row){
      this.source()
      this.category()
      this.foodId = row.foodId
      this.ruleForm =JSON.parse(JSON.stringify(row))
      this.centerDialogVisible=true
      this.title='修改'

      this.disabledBt=true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addEiat()

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.centerDialogVisible=false
    },
    //  添加
    addEiat(){
      let param =JSON.parse(JSON.stringify(this.ruleForm))
      delete param.createTime
      delete param.predictArrivedDate
      if(this.foodId){
        param.foodId=this.foodId
        foodUpdate('api/food/update', 'PUT', param).then((res) => {
          console.log('fdfdfdfdf',res)
          this.centerDialogVisible=false
          Message({
            type: "success",
            message: "编辑成功"
          });
          this.getList()
        }).catch((err) => {
          console.log(err.message)
        })

      }else{
        foodData('api/food', 'post', param).then((res) => {
          console.log('fdfdfdfdf',res)
          this.centerDialogVisible=false
          Message({
            type: "success",
            message: "添加成功"
          });
          this.getList()
        }).catch((err) => {
          console.log(err.message)
        })
      }


    },

    //  修改
    handleCurrentChange(){
      this.getList();
    },
    activeBtn(item){
      this.activeIndex=item.id
      this.status=item.status
      this.todayData.pagination.page = 1
      this.todayData.pagination.limit = 10
      this.getList()
    },
    //  搜索
    searchBtn(){
      this.todayData.pagination.page =1
      this.todayData.pagination.limit=10
      this.getList()
    },
    getList(){
      let param={}
      if(this.status!==''){
        param.status=this.status
      }
      param.pageNum=this.todayData.pagination.page
      param.pageSize=this.todayData.pagination.limit
      if(this.select=='1'){
        param.foodNo=this.foodNo
      }
      if(this.select=='2'){
        param.foodName=this.foodNo
      }
      foodList('api/food/list', 'get', param).then((res) => {
        console.log(res)
        this.todayData.tableData=res.data.rows||[]
        //this.foodNo=''
        this.todayData.pagination.total=res.data.total
      }).catch((err) => {
        console.log(err.message)
      })


    },
    foodOverviewEvent(){
      let param ={}
      foodOverview('api/food/overview', 'get', param).then((res) => {
        console.log(res)
        this.foodOverviewData=res.data
      }).catch((err) => {
        console.log(err.message)
      })
    },
    source(){
      let param ={}
      sourceData('api/food/source', 'get', param).then((res) => {
        console.log(res)
        this.sourceArray=res.data
      }).catch((err) => {
        console.log(err.message)
      })
    },
    category(){
      let param ={}
      categoryData('api/food/category', 'get', param).then((res) => {
        console.log(res)
        this.categoryArray=res.data
      }).catch((err) => {
        console.log(err.message)
      })
    },
  },

  created(){
    this.getList()
    this.foodOverviewEvent()
  }
}
</script>

<style lang="scss" scoped>
.reportFormsBox{
  display: flex;
  flex-direction: column;
  .reportList{
    width: 100%;
    flex-direction: column;
    h2{
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
    }
    .flexBox{
      width: 100%;
      display: flex;
      align-items: center;
      .itemBox{
        width: 24%;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        box-sizing: border-box;
        padding:20px 10px;
        box-sizing: border-box;
        margin-right: 20px;
        .left{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span{
            display: block;
            margin-bottom: 10px;
            font-size: 22px;
          }
          h2{
            font-size: 40px;
          }
        }
        img{
          width: 75px;
          height: 75px;
        }
      }
      .itemBox:first-child{
        background-color: #F3E1E5;
      }
      .itemBox:nth-child(2){
        background-color: #E5DDF4;
      }
      .itemBox:nth-child(3){
        background-color: #D0E7DA;
      }
      .itemBox:last-child{
        background-color: #DCE4F5;
      }
    }
  }
  .tableBox{
    display: flex;
    flex-direction: column;
    h2{
      width: 100%;
      text-align: left;
      margin: 10px 0;
    }
    .tableList{
      display: flex;
      justify-content: space-between;
      height: 40px;
      .leftTitle{
        display: flex;
        border-bottom: 1px solid #e4e7ed;
        flex: 1;
        span{
          padding:0 13px;
          height: 40px;
          margin-right: 6px;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          cursor: pointer;
          border: 1px solid #e4e7ed;
          border-bottom: none;
          border-radius: 4px 4px 0 0;
          box-sizing: border-box;
        }
        span.active{
          color: #082844;
          font-weight: bold;
          height: 41px;
          background-color: #fff;
          border-right-color: #dcdfe6;
          border-left-color: #dcdfe6;
          border-bottom-color: #fff;
        }
      }
      .rightInput{
        width: 480px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.aliasBox{
  width: 100%;
  display: flex;
  align-items: center;
  span{
    width: 94px;

  }
}
</style>
