import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/index.vue'
import home from '../views/home.vue'
import Package from '../views/reportForms/package/index.vue'
import Consume from '../views/reportForms/package/consume.vue'
import Inventory from '../views/reportForms/package/inventory.vue'
import Distribution from '../views/reportForms/package/distribution.vue'
import addUser from '../views/add/index.vue'

import Others from '../views/Kanban/Others.vue'
import Seafood from '../views/Kanban/Seafood.vue'
import Cold from '../views/Kanban/Cold.vue'
import hot from '../views/Kanban/hot.vue'
import Unused from '../views/Kanban/Unused.vue'
import All from '../views/Kanban/All.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    redirect: '/home/All',
    children:[
      {
       path:'package',
       name: 'package',
       component: Package,
      },
      {
        path:'consume',
        name: 'consume',
        component: Consume
       },
       {
        path:'inventory',
        name: 'inventory',
        component: Inventory
       },
       {
        path:'distribution',
        name: 'distribution',
        component: Distribution,
       },
       {
        path:'Others',
        name: 'Others',
        component: Others,
       },
       {
        path:'Seafood',
        name: 'Seafood',
        component: Seafood,
       },
       {
        path:'Cold',
        name: 'Cold',
        component: Cold,
       },
       {
        path:'hot',
        name: 'hot',
        component: hot,
       },{
            path:'All',
            name: 'All',
            component: All,
        },
       {
        path:'Unused',
        name: 'Unused',
        component: Unused,
       },
       {
        path:'adduser',
        name: 'adduser',
        component: addUser,
       }
    ]
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
 
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
