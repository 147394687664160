<template>
    <div>

        <el-dialog
               title="新增"
              style="text-align:left;"
              :visible.sync="centerDialogVisible"
              width="600px">
             <div class="loginBox">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    <!-- <el-form-item label="商品編號" prop="foodNo">
                      <el-input v-model="ruleForm.foodNo" :disabled="disabledBt"></el-input>
                    </el-form-item> -->
                    <el-form-item label="商品名稱" prop="foodName">
                      <el-input v-model="ruleForm.foodName" ></el-input>
                    </el-form-item>

                    <el-form-item label="商品分類" prop="defineCategory">
                      <el-select v-model="ruleForm.defineCategory" placeholder="请选择" style="width:100% ;">
                          <el-option
                            v-for="item in categoryArray"
                            :key="item.defineCategoryId"
                            :label="item.defineCategoryName"
                            :value="item.defineCategoryName">
                          </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="所屬部門" prop="department">
                      <el-select v-model="ruleForm.department" placeholder="请选择" style="width:100% ;">
                          <el-option
                            v-for="item in departmentArray"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商品別名" >
                      <el-input v-model="ruleForm.foodAbbr"></el-input>
                    </el-form-item>

                    <el-form-item label="商品來源">

                      <el-select v-model="ruleForm.source" placeholder="请选择" style="width:100% ;">
                          <el-option
                            v-for="item in sourceArray"
                            :key="item.foodSourceId"
                            :label="item.foodSourceName"
                            :value="item.foodSourceName">
                          </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品預警值">
                      <el-input v-model="ruleForm.warningCount"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="submitForm('ruleForm')">確定</el-button>
                      <el-button @click="resetForm">取消</el-button>
                    </el-form-item>
                  </el-form>
               </div>
              
            </el-dialog>



    </div>


</template>

<script>
import { Message } from "element-ui";
import { foodData,sourceData,categoryData} from '../../../../request/requrst'
export default {
  
    data() {

        return {
            centerDialogVisible:false,
            departmentArray:[{
              value: 'hot',
              label: 'hot'
            }, {
              value: 'Others',
              label: 'Others'
            }, {
              value: 'Seafood',
              label: 'Seafood'
            }, {
              value: 'Cold',
              label: 'Cold'
            }, {
              value: 'Unused',
              label: 'Unused'
            }],
          sourceArray:[],
          categoryArray:[],
          ruleForm: {
            foodNo: '', // 商品编号
            foodName: '', // 商品名称
            defineCategory:'', // 商品分类
            department:'hot', // 所属部门
            foodAbbr:'', // 商品别名
            source:'', // 商品来源
            warningCount: '', // 商品预警值
          },
          rules:{
            foodNo: [
                { required: true, message: '請輸入商品編號', trigger: 'blur' },
             ],
             foodName: [
               { required: true, message: '請輸入商品名稱', trigger: 'blur' }
             ],
             defineCategory: [
               { required: true, message: '請輸入商品分類', trigger: 'blur' }
             ],
             department: [
               { required: true, message: '請輸入所屬部門', trigger: 'change' }
             ]
           },
        }
    },
    watch:{
      centerDialogVisible(val, value){
        if(val===false){
          console.log(this.ruleForm)
           Object.keys(this.ruleForm).forEach(key => (this.ruleForm[key] = '')); 
           this.ruleForm.department='hot'
        } 
      }
    },
    methods:{
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.addEiat()
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
       //  添加
       addEiat(){
        let param =JSON.parse(JSON.stringify(this.ruleForm))
          delete param.createTime
          delete param.predictArrivedDate
          foodData('api/food', 'post', param).then((res) => {
             console.log('fdfdfdfdf',res)
             this.centerDialogVisible=false
             Message({
              type: "success",
              message: "添加成功"
            });
            this.$nextTick(()=>{
                this.$parent.foodDetailsData()
            })
           }).catch((err) => {
            console.log(err.message)
         }) 
      },
        source(){
        let param ={}
        sourceData('api/food/source', 'get', param).then((res) => {
            console.log(res)
            this.sourceArray=res.data
           }).catch((err) => {
            console.log(err.message)
         }) 
      },
      category(){
        let param ={}
        categoryData('api/food/category', 'get', param).then((res) => {
            console.log(res)
            this.categoryArray=res.data
           }).catch((err) => {
            console.log(err.message)
         }) 
      },
      resetForm() {
        this.centerDialogVisible=false
      },
    }
  

}
</script>