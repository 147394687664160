// 二次封装 axios  (拦截器)

import axios from "axios";

import  router from '@/router'
import { MessageBox, Message } from 'element-ui'
// 借助 qs 将对象进行序列化
import qs from 'qs'

// baseURL 不可写错
 
const service = axios.create({
  baseURL:process.env.VUE_APP_ENV== "development"?'/api':process.env.VUE_APP_BASE_URL,
  timeout: 30000
})




service.interceptors.request.use((config) => {
  /* 发送请求时, 需要做的事 */

  // 设置请求头信息, 例如 token
  const token = 'Bearer'+ ' '+sessionStorage.getItem('token')
  if(token){
    config.headers['Authorization'] = token
   
  }
  // 当请求类型为 get 时, 则将 参数拼接
  if(config.method === 'get') {
    config.params = config.data
  }
  if(config.method === 'put') {
   
    config.data = config.data
  }
  if(config.method === 'DELETE') {
    config.params = config.data
  }
  // 当请求类型为 post 时, 按后端需要, 转成需要的类型 (这里转为 formData 格式)
  if(config.method === 'post') {
   
    // 具体传参类型, 根据后端接口要求进行设置
    // 参数类型为 formData 格式时, 设置 Content-Type 为 application/x-www-form-urlencoded
    // config.headers['Content-Type'] = 'application/x-wMw-form-urlencoded;charset=UTF-8'

    // config.headers['Access-Token']
    config.data = config.data
  }


  return config;
}, 
(error) => {

  return Promise.reject(error);
})


let isToken =false
service.interceptors.response.use((response) => {
  // 接口响应时需要做的事

  let res = response.data
  if(res.code ==  401 || res.code ==500) {
    if(!isToken){
      isToken=true
      Message({
        message: res.msg,
        type: 'error',
      })
      setTimeout(()=>{
        isToken =false
      },300)
       if(res.code ==  401){
        sessionStorage.clear()
        router.replace('/')
        window.location.reload()
       }
    }
   return Promise.reject(new Error( res.msg || 'Error'))
  } else {
    return res
  }

},
(error) => {
  return Promise.reject(error)
})



export default service

