import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/public.css';
import 'element-ui/lib/theme-chalk/index.css';
import {
  MessageBox,
  Message
} from "element-ui";
import { Button ,Form ,FormItem,Input,Container,
  Header,
  Dialog,
  Select,
  datePicker,
  Option,
  Aside,
  Upload,
  Tag,
  Checkbox,
  CheckboxGroup,
Main,Menu,Submenu,MenuItem,Dropdown,DropdownMenu,DropdownItem,Tabs,TabPane,Pagination,Table,TableColumn,Loading} from 'element-ui';
  Vue.use(Tag)
  Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(datePicker);
Vue.use(Upload);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.component(MessageBox.name, MessageBox);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
 
  if (to.path === '/') return next();
  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  //如果没有获取到token那么返回登录
  if (!tokenStr) return next('/')
  //得到token就跳转页面，放行
  next()
})
