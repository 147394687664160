<template>
    <div>
            <div class="propBox">
                <div class="headerBOX">
                    <el-button size="medium" round @click.native="exportExcel()">模板下載</el-button>
                    <el-button size="medium" round @click.native="uploadBtn">導入</el-button>
                </div>
                <div class="tableBox">

                    <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%">
                        <el-table-column property="logId" label="用戶">
                        </el-table-column>
                        <el-table-column property="remark" label="備註">
                        </el-table-column>

                        <el-table-column property="createTime" label="創建時間">
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="100">
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row)" type="text" size="small">下载</el-button>
                            </template>
                            </el-table-column>
                    </el-table>




                    <div class="pageBox">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage1" :page-size="pagination.limit"
                            layout="total, prev, pager, next" :total="pagination.total">
                        </el-pagination>
                    </div>
                </div>

            </div>



        <el-dialog title="导入信息" :visible.sync="uploadVisible" width="600px" style="text-align:left;">
            <div class="propBox">




                <el-upload
                    class="upload-demo"
                    ref="upload"
                    multiple
                    action=""
                    :on-change="fileChange"
                    :on-remove="removeFile"
                    accept=".xlsx, .xls"
                    :file-list="fileList"
                    :auto-upload="false">
                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>

                    <el-input
                                style="margin-top:30px;"
                                type="textarea"
                                :rows="2"
                                maxlength="100"
                                show-word-limit
                                placeholder="请输入内容"
                                v-model="remark">
                    </el-input>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="uploadVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUpload()">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import axios from "axios";
import { Header } from 'element-ui'
import { Message } from "element-ui";
import { importList,foodStocktaking,foodRestocking,foodTemplate } from '../../../../request/requrst'
export default {
    data() {
        return {
            fileList:[],
            multiple: false,
            propVisible: true,
            uploadVisible: false,
            currentPage1: 1,
            remark:'',
            addurl: '',
            tableData: [],
            pagination: {
                page: 1,//分页数
                limit: 10,//每页条数
                total: 0//总条数
            }
        }
    },
    props:['importType'],
    watch:{
      centerDialogVisible(val, value){
        if(val===false){
            this.fileList=[]
            this.remark=''
            this.pagination.page=1
        }
      }
    },
    computed:{
        baseUrlData(){
            return process.env.VUE_APP_BASE_URL+'/api/food/template'
        },
        baseUrl(){
            return process.env.VUE_APP_BASE_Dis
        }
    },
    methods: {
        // 下载

        handleClick(row){
           console.log(row)
           console.log('dfdfdfdfdf',this.baseUrl+row.filePath)

           var a = document.createElement("a");
            a.href = this.baseUrl+row.filePath;
            a.download = "商品信息.xlsx";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },

        fileChange(file, fileList) {
            this.fileList = fileList;
           let existFile = fileList.slice(0, fileList.length - 1).find(f => f.name === file.name)
            if (existFile) {
                Message({
                type: "error",
                message: "请上传不同的文件"
                });
                fileList.pop()
            }

         },
         removeFile(file, fileList) {
            this.fileList = fileList//此处fileList为删除文件后，剩余的文件
         },


      submitUpload() {
        if(this.fileList.length===0){
            Message({
                type: "error",
                message: '請上傳.xlsx表格類型文件'
                });

            return
         }

        if(!this.remark){
            Message({
                type: "error",
                message: '備註不能為空'
                });

            return
         }

        let {uploadFiles} = this.$refs.upload
        let formData = new FormData()
        uploadFiles.forEach(item => {
            formData.append('file', item.raw)
           // console.log('ooooooooooo',item)
         })
       formData.append('remark', this.remark)

     if(this.importType===1){

      foodRestocking('api/food/restocking', 'post',  formData,{headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
                Message({
                type: "success",
                message: res.msg
                });
                this.uploadVisible=false
                this.importListData()
                this.$parent.getList()
            }).catch((err) => {
                console.log(err.message)
        })
     }else{
        foodStocktaking('api/food/stocktaking', 'post',  formData,{headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
                Message({
                type: "success",
                message: res.msg
                });
                this.uploadVisible=false
                this.importListData()
                this.$parent.getList()
            }).catch((err) => {
                console.log(err.message)
        })
       }
      },



        exportExcel(){
          var params = {}
          axios.get(this.baseUrlData, {
            params: params,
            responseType: 'blob',
            headers: {
              'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('token')
            }
          }).then(res => {
            console.log(res);
            let blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"}); // 为blob设置文件类型，这里以.xlsx为例
            let url = window.URL.createObjectURL(blob); // 創建一个临时的url指向blob对象
            let a = document.createElement("a");
            a.href = url;
            a.download = decodeURIComponent(res.headers['content-disposition']);
            a.click();
            // 释放这个临时的对象url
            window.URL.revokeObjectURL(url);
          })
      },
        uploadBtn() {
            this.uploadVisible = true
        },
        importListData() {
            let param = {}
            param.pageNum = this.pagination.page
            param.pageSize = this.pagination.limit
            param.importType = this.importType
            importList('api/food/import/list', 'get', param).then((res) => {
                this.tableData = res.data.rows
                this.pagination.total=res.data.total
            }).catch((err) => {
                console.log(err.message)
            })
        },
        handleSizeChange(val) {
            this.pagination.limit = val
            this.importListData()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pagination.page = val
            this.importListData()
        },
    },
    created() {
        this.importListData()
    }

}
</script>
<style lang="scss" scoped>
.propBox {
    width: 100%;
    display: flex;
    flex-direction: column;

    .tableBox {
        width: 100%;
        height: 300px;
    }
}

.pageBox {
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    text-align: center;
}
.headerBOX{
    text-align: left;
    margin-bottom: 10px;
}
</style>
