import requestApi from './axios'


// 封装请求接口
export const requestMethods = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 新增管理员  / 修改用户
export const user = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


export const deluser = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}



// 管理员列表
export const userList = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}




//  退出
export const logout = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 食材消耗列表
export const foodList = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 食材消耗-新增食材

export const foodSave = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 食材消耗-修改食材

export const foodUpdate = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 食材消耗-食材库存信息总览

export const foodOverview = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// 食材管理 - 包装追踪列表
export const trackList = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}
// 食材管理 - 包装追踪列表
export const updatePackageTrack = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}
// 包装追踪管理 -包装追踪总览

export const trackOverview = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// 食材庫存管理 - 商品库存盘点

export const foodStocktaking = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// 食材庫存管理 - 商品库存补货


export const foodRestocking = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 食材庫存管理 - 导入记录列表


export const importList = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// 食材庫存管理 - 模板下载


export const foodTemplate = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// 食材庫存管理 - 商品库存导出


export const foodExport = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}



// 包装追踪管理 - 单个包装信息中的商品详情



export const foodDetails = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


export const modify = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

export const rematch = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

// 包装追踪管理 - 包装信息状态变更

export const handle = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// OpenCartApi - 同步opencart平台数据
export const syncData = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


// OpenCartApi - 同步opencart平台数据
export const overview = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}



// OpenCartApi - 同步opencart平台数据
export const packageTrack = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


export const foodData = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


export const sourceData = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}

export const categoryData = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}


export const aliasData = (url, type, data) => {
  return requestApi({
    url: url,
    method: type,
    data: data
  })
}
