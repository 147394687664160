<template>
    <div class="addUser">
             <div class="userTop">
                  <h2>管理員列表</h2>
                  <el-button type="primary" size="small" round @click="centerDialogVisible = true">新增用户</el-button>
             </div>
          

           <div class="userList">
            <el-table
              ref="singleTable"
              :data="tableData"
              highlight-current-row
              style="width: 100%">
              <el-table-column
                property="userId"
                label="用戶"
                >
              </el-table-column>
              <el-table-column
                property="userName"
                label="用戶名"
                >
              </el-table-column>

              <el-table-column
                property="createTime"
                label="創建時間"
               >
              </el-table-column>
              <el-table-column
                 
                  label="用戶類型">
                  <template slot-scope="scope" >
                    <el-button :type="scope.row.userType===1?'primary':'success'"  size="small" plain>{{scope.row.userType===1?'管理员':'普通用户'}}</el-button>
                  </template>
                </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  v-if="$store.state.loginUser.userName=='admin'"
                  width="120">
                  <template slot-scope="scope" >
                    <el-button @click="delClick(scope.row)"    type="text" size="small">刪除</el-button>
                    <el-button @click="eithClick(scope.row)"    type="text" size="small">修改密碼</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="pagination.limit"
              layout="total, prev, pager, next"
              :total="pagination.total">
            </el-pagination>
            </div>
            
           </div>

           <el-dialog
              :title="title"
              :visible.sync="centerDialogVisible"
              width="580px">


             <div class="loginBox">
               <el-form  ref="ruleForm" :model="form"   label-width="80px" class="imputflex">
                  <el-form-item label="用戶名：" prop="user" >
                    <el-input  v-model="form.user" :disabled="disabled"></el-input>
                  </el-form-item>
                  <el-form-item label="密碼："  prop="pws">
                    <el-input v-model="form.pws"  type="password"  autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="用戶類型：">
                    <el-select v-model="form.userType" placeholder="請選擇">
                       <el-option label="普通用户" :value="0"></el-option>
                       <el-option label="管理员"  :value="1"></el-option>
                  </el-select>
                  </el-form-item>
                </el-form>
               </div>
              
              <span slot="footer" class="dialog-footer" style="text-align:center;">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click.native="submitForm" >確 定</el-button>
              </span>
            </el-dialog>
    </div>
  </template>
  
  <script>
  import { user,userList ,deluser} from '../../request/requrst'
  import { Message } from "element-ui";
  export default {
    data() {
      return {
        centerDialogVisible:false,
        currentPage1: 1,
        tableData: [],
        disabled:false,
        title:'新增用戶',
        form:{
          user:'',
          pws:'',
          userType:0
        },
        userId:'',
        pagination:{
                page:1,//分页数
                limit:10,//每页条数
                total:0//总条数
        }
      } 
    },
    watch:{
      centerDialogVisible(val, value){

        if(val===false){
            this.form.user=''
            this.form.pws=''
            this.disabled=false
            
        }
      }
    },
    methods:{
      //  编辑用户
      eithClick(item){
         this.centerDialogVisible=true
         this.title='修改密碼'
         this.form.user =item.userName
         this.disabled=true
          this.userId =item.userId
      },
      delClick(item){
         let  param ={}
         param.userId=item.userId
        deluser('/api/user', 'DELETE', param).then((res) => {
            console.log(res)
            this.getResult()
            Message({
              type: "success",
              message: "刪除成功"
            });
           }).catch((err) => {
            console.log(err.message)
            }) 
      },
      submitForm() {
        if(this.userId){
          let param={}
             param.userId=this.userId
             param.passWord=this.form.pws
            //  param.userName=this.form.user
            user('/api/user', 'put', param).then((res) => {
            console.log(res)
            this.centerDialogVisible=false
            this.getResult()
            Message({
              type: "success",
              message: "修改成功"
            });
           }).catch((err) => {
            console.log(err.message)
            }) 

        }else{
          this.addResult()
        }
        
      },
      handleSizeChange(val) {
        this.pagination.limit=val
        this.getResult()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.page =val
        this.getResult()
      },
      getResult(){
        let param={}
        param.pageNum=this.pagination.page
        param.pageSize=this.pagination.limit
        userList('/api/user/list', 'get', param).then((res) => {
            console.log('22222',res)
             this.tableData =res.data.rows || []
             this.pagination.total=res.data.total
           }).catch((err) => {
            console.log(err.message)
         }) 

      },
      addResult(){
        let param ={}
        if(!this.form.user){
              Message({
              type: "error",
              message: "用戶名不能為空"
            });
            return
        }
        if(!this.form.pws){
            Message({
              type: "error",
              message: "密碼不能為空"
            });
            return
          }
        param.userName=this.form.user
        param.passWord=this.form.pws
        param.userType=this.form.userType
        user('/api/user', 'post', param).then((res) => {
            console.log(res)
            this.form.user=''
            this.form.pws=''
            this.centerDialogVisible=false
            Message({
              type: "success",
              message: "添加成功"
            });
            this.getResult()
           }).catch((err) => {
            console.log(err.message)
         }) 
      }
    },
    created(){
      this.getResult()
    }
  }
  </script>

  <style lang="scss" scoped>
  .addUser{
    display: flex;
    flex-direction: column;
    text-align: left;
    .userTop{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .userList{
      display: flex;
      flex-direction: column;
    }
  }
  .loginBox{
        width: 500px;
        border-radius: 8px;
        background: #fff;
        padding: 20px;
        h2{
          margin-bottom: 20px;
          font-size: 28px;
          color: #333333;
        }
        .imputflex{
          width: 400px;
          .inputWidth{
            width: 200px;
            
          }
        }
       
     }
     .pageBox{
      width: 100%;
      background-color: #fff;
      padding: 20px 0;
      text-align: center;
     }
  </style>
  